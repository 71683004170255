import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Balance`}</p>
    <p>{`High Hang Squat Snatch 4×6\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Age Group Qualifier Test 5`}</p>
    <p>{`12-9-6:`}</p>
    <p>{`OHS’s (from floor) (135/95) (AGQ/RX+ 165/115)`}</p>
    <p>{`Burpee Box Jump Overs (box facing)(24/20″)(AGQ/RX+ 30″)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      